
import { mapGetters } from 'vuex'
import ConnectFailedIcon from './ConnectFailedIcon'
import {
  STATUS_FAILED
} from '~/helpers/connect-distributors'

export default {
  name: 'TopMenuMyDistributors',
  components: { ConnectFailedIcon },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      practiceLocation: 'user/practiceLocation'
    }),
    showConnectFailedIcon() {
      return !!this.practiceLocation?.distributors?.find((d) => d.status === STATUS_FAILED)
    }
  },
  methods: {}
}
